var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cNU-AWo5YG2EwkMV9SRqg"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/renty-ai/renty-ai-21d1f9c8d6933e3d9eedad6b6c107d7343ba973a";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import * as FullStory from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'

const SENTRY_ORG = process.env.SENTRY_ORG || process.env.NEXT_PUBLIC_SENTRY_ORG || ''
const FULLSTORY_ORG = process.env.FULLSTORY_ORG || process.env.NEXT_PUBLIC_FULLSTORY_ORG || ''
const SENTRY_RELEASE = process.env.SENTRY_RELEASE || ''

if (FULLSTORY_ORG) {
  FullStory.init({ orgId: FULLSTORY_ORG })
}

Sentry.init({
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,

  release: SENTRY_RELEASE,

  // environment: process.env.NEXT_PUBLIC_ENV || 'development',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new SentryFullStory(SENTRY_ORG, { client: FullStory as any }),
    new Sentry.BrowserTracing()
    // new Sentry.Replay({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: true,
    //   blockAllMedia: true
    // })
  ]
})
